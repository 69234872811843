<template>
  <div class="container">
    <div class="checkin">
      <el-row :gutter="20">
        <el-col :span="5">
          <serviceleft :activeIndex="leftPannelSelected"/>
        </el-col>
        <el-col :span="19">
          <div style="width:100%;overflow:hidden;border-radius:10px; height:120px;">
            <img src="https://static.a6air.com/resource/banner/20210330/pannel_top_checkticket.jpg">
          </div>
          <div class="right_pannel">
            <div style="width:900px; padding-left: 20px;" v-show="!detailVisible">
              <!-- <div class="panel-header" style="width: 880px;">
                                <ul class="panel-tabs" style="width:400px;">
                                    <li :class="loginType=='AIRPORT'?'active':''">
                                        <a href="javascript:void(0)" @click="changeTab('AIRPORT')">客票验真</a>
                                    </li>
                                </ul>
              </div>-->
              <div>
                <div class="form-panel" style="margin-top: 10px;" v-if="loginType=='AIRPORT'">
                  <div class="form-panel-item">
                    <label class="form-label" style="width:50px;" for="certNo">票号：</label>
                    <el-input
                      id="certNo"
                      v-model="checkTicketFilter.tktNo"
                      ref="tktNo"
                      placeholder="请输入票号"
                      style="width:135px"
                    ></el-input>
                  </div>
                  <div class="form-panel-item">
                    <label class="form-label" style="width:50px;" for="certNo">姓名：</label>
                    <el-input
                      id="certNo"
                      v-model="checkTicketFilter.passName"
                      ref="passName"
                      placeholder="请输入姓名"
                      style="width:120px"
                    ></el-input>
                  </div>
                  <div class="form-panel-item">
                    <label class="form-label" style="width:50px;" for="certNo">航班日期：</label>
                    <el-date-picker v-model="checkTicketFilter.deptDate" value-format="yyyy-MM-dd" style="width:100%">
                    </el-date-picker>
                  </div>
                  <div class="form-panel-item">
                    <label class="form-label" style="width:50px;" for="certNo">出发到达：</label>
                    <div class="route-wrap">
                      <div style="position: relative">
                        <el-autocomplete class="inline-input" style="width:130px;"
                                         v-model="checkTicketFilter.depAirport.name" clearable :fetch-suggestions="(q,cb)=>querySearch('depAirportPannel',q,cb)"
                                         placeholder="到达机场" @select="handleSelectDepAirport">
                          <template slot-scope="{ item }">
                            <div class="name">{{ item.chineseName }}</div>
                          </template>
                        </el-autocomplete>
                        <AirportPannel ref="depAirportPannel"  @selected="handleSelectDepAirport"></AirportPannel>
                      </div>
                      <span class="el-icon-sort" @click="exchangeAirport" style="width:40px;text-align: center;transform: rotate(90deg);color:#d7013a"></span>
                      <div style="position: relative">
                        <el-autocomplete class="inline-input" style="width:130px;"
                                         v-model="checkTicketFilter.arrAirport.name" clearable :fetch-suggestions="(q,cb)=>querySearch('arrAirportPannel',q,cb)"
                                         placeholder="到达机场" @select="handleSelectArrAirport">
                          <template slot-scope="{ item }">
                            <div class="name">{{ item.chineseName }}</div>
                          </template>
                        </el-autocomplete>
                        <AirportPannel ref="arrAirportPannel" @selected="handleSelectArrAirport"></AirportPannel>
                      </div>
                    </div>
                  </div>
                  <div class="form-panel-item">
                    <label class="form-label" style="width:60px;" for="certNo">验证码：</label>
                    <el-input
                      id="certNo"
                      ref="tel"
                      v-model="checkTicketFilter.picValidateCode"
                      placeholder="请输入验证码"
                      style="width:200px;"
                    ></el-input>
                    <el-image
                      style="width: 100px;padding-left: 10px;"
                      :src="picValidateUrl"
                      @click="changeValidateCode"
                    ></el-image>
                    <div @click="changeValidateCode" class="change-btn">看不清，换一张</div>
                  </div>
                  <div class="form-panel-item flex-center">
                    <el-button
                      type="danger"
                      style="width:80px;margin-left: 20px;border-radius:5px;"
                      @click="validateticketHandler"
                    >查&nbsp;询</el-button>
                  </div>
                </div>
                <el-row style="margin-top: 10px;" v-if="loginType=='FLIGHTNO'">
                  <el-col :span="7" class="form-group">
                    <label class="form-label" for="certNo">航班号：</label>
                    <el-input
                      id="certNo"
                      v-model="checkTicketFilter.flightNo"
                      placeholder="请输入证件号/票号"
                      style="width:150px"
                    ></el-input>
                  </el-col>
                  <el-col :span="7" class="form-group">
                    <label class="form-label" for="certNo">出发日期：</label>
                    <el-input
                      id="certNo"
                      v-model="checkTicketFilter.deptDate"
                      placeholder="请输入证件号/票号"
                      style="width:150px"
                    ></el-input>
                  </el-col>
                  <el-col :span="2" class="form-group">
                    <el-button type="danger" style="width:80px;margin-left: 20px;">查&nbsp;询</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="ticket-detail" v-show="detailVisible">
              <div
                style="width:800;padding-left:150px;margin-top:20px;min-height: 100px;"
                v-for="item in tableData"
                v-bind:key="item.ticketNo"
              >
                <div class="item">
                  <div class="item-title">姓名</div>
                  <div class="item-value">{{ item.passName }}</div>
                </div>
                <div class="item">
                  <div class="item-title">票号</div>
                  <div class="item-value">{{ item.ticketNo }}</div>
                </div>
                <div class="item">
                  <div class="item-title">票价</div>
                  <div class="item-value">{{ item.totalPrice.amount }}</div>
                </div>

                <div v-for="(seg, idx) in item.segs" v-bind:key="seg.deptTime" style="margin-top: 20px;">
                  <div class="item" v-if="item.segs.length > 1">
                    <div class="item-title">行程序号</div>
                    <div class="item-value">{{ idx + 1 }}</div>
                  </div>
                  <div class="item">
                    <div class="item-title">航班号</div>
                    <div class="item-value">{{ seg.flightNo }}</div>
                  </div>
                  <div class="item">
                    <div class="item-title">航班日期</div>
                    <div class="item-value">{{ seg.deptTime | flightDate}}</div>
                  </div>
                  <div class="item">
                    <div class="item-title">出发</div>
                    <div class="item-value">{{ seg.deptAirportCnName }}</div>
                  </div>
                  <div class="item">
                    <div class="item-title">到达</div>
                    <div class="item-value">{{ seg.arrAirportCnName }}</div>
                  </div>
                  <div class="item">
                    <div class="item-title">状态</div>
                    <div class="item-value">{{ seg.ticketStatus }}</div>
                  </div>
                  <!-- <el-row style="margin-top: 2px;">
                    <el-col :span="8" class="form-group">
                      <label class="form-label" for="certNo">航段：</label>
                      <label
                        id="certNo"
                        class="form-label"
                        style="text-align: left;padding-left: 5px;"
                      >{{ index + 1 }}</label>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 2px;">
                    <el-col :span="8" class="form-group">
                      <label class="form-label bg" for="certNo">航班号：</label>
                      <label
                        id="certNo"
                        class="form-label"
                        style="text-align: left;padding-left: 5px;"
                      >{{ seg.flightNo }}</label>
                    </el-col>
                    <el-col :span="12" class="form-group">
                      <label class="form-label bg" for="certNo">起飞日期：</label>
                      <label
                        id="certNo"
                        class="form-label"
                        style="width:140px; text-align: left;padding-left: 5px;"
                      >{{ seg.deptTime }}</label>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 2px;">
                    <el-col :span="8" class="form-group">
                      <label class="form-label bg" for="certNo">出发机场：</label>
                      <label
                        id="certNo"
                        class="form-label"
                        style="text-align: left;padding-left: 5px;"
                      >{{ seg.deptAirportCode }}</label>
                    </el-col>
                    <el-col :span="12" class="form-group">
                      <label class="form-label bg" for="certNo">到达机场：</label>
                      <label
                        id="certNo"
                        class="form-label"
                        style="width:140px; text-align: left;padding-left: 5px;"
                      >{{ seg.arrAirportCode }}</label>
                    </el-col>
                  </el-row> -->
                  <!-- <el-row style="margin-top: 2px;">
                    <el-col :span="8" class="form-group">
                      <label class="form-label bg" for="certNo">舱位：</label>
                      <label
                        id="certNo"
                        class="form-label"
                        style="text-align: left;padding-left: 5px;"
                      >{{ seg.cabinCode }}</label>
                    </el-col>
                    <el-col :span="12" class="form-group">
                      <label class="form-label bg" for="certNo">使用限制：</label>
                      <label
                        id="certNo"
                        class="form-label"
                        style="width:140px; text-align: left;padding-left: 5px;"
                      >{{ seg.ticketStatus }}</label>
                    </el-col>
                  </el-row> -->
                </div>
              </div>
            </div>
            <div style="margin-top:20px;">
              <div class="warm-prompt">
                <div>
                  <p style="text-align: center;">此系统仅提供湖南航空电子客票的查询校验，若需要进一步校验，请拨打湖南航空客服热线<strong style="color: #D70039">4008337777</strong></p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import serviceleft from "../../components/ServiceLeft";
import { fetchAirports, validateticket } from "@/api/newsapi";
import moment from 'moment';
import AirportPannel from "@/components/AirportPannel.vue";

export default {
  components: {AirportPannel, serviceleft },
  data() {
    return {
      leftPannelSelected: "li_ticket",
      loginType: "AIRPORT",
      input: "",
      emptySlot: "",
      baseValidateUrl: "/api/user/createCaptcha?random=",
      picValidateUrl: "",
      scheduleLoading: false,
      checkTicketFilter: {
        tktNo: "",
        passName: "",
        tel: "",
        picValidateCode: "",
        deptDate:null,
        depAirport:{
            name:'',
            code:''
        },
        arrAirport:{
          name:'',
          code:''
        },
      },
      tableData: [],
      detailVisible: false,

      restaurants:[]
    };
  },
  created() {
    this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
  },
  mounted(){
    document.title = this.$route.meta.title;
    this.restaurants = fetchAirports();
  },
  filters:{
     flightDate(val){
        return  moment(val).format('MM-DD HH:mm')
     }
  },
  methods: {
    selectPannel(pannelId) {
      // console.log(pannelId);
      document.getElementById("li_booking").setAttribute("class", "");
      document.getElementById("li_aoc").setAttribute("class", "");
      document.getElementById("li_checkin").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_xl").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    changeValidateCode() {
      this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
    },
    changeTab(tab) {
      this.loginType = tab;
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 2) {
        return `background-color: rgb(210, 209, 208)`;
      } else {
        return "";
      }
    },
    validateticketHandler() {
      if (this.checkTicketFilter.tktNo.length <= 0) {
        this.$message.error("证件号/票号不能为空");
        this.$refs.tktNo.focus();
        return;
      }
      if (this.checkTicketFilter.passName.length <= 0) {
        this.$message.error("旅客姓名不能为空");
        this.$refs.passName.focus();
        return;
      }
      if (!this.checkTicketFilter.deptDate) {
        this.$message.error("航班日期不能为空");
        return;
      }
      if (!this.checkTicketFilter.depAirport.code || !this.checkTicketFilter.arrAirport.code) {
        this.$message.error("出发到达不能为空");
        return;
      }
      // if (this.checkTicketFilter.tel.length <= 0) {
      //   this.$message.error("旅客联系电话不能为空");
      //   this.$refs.tel.focus();
      //   return;
      // }
      this.scheduleLoading = true;
      this.tableData = [];
      this.emptySlot = "";
      const params = {
        passName:this.checkTicketFilter.passName,
        picValidateCode:this.checkTicketFilter.picValidateCode,
        tktNo:this.checkTicketFilter.tktNo,
        depDate:this.checkTicketFilter.deptDate,
        arrAirport:this.checkTicketFilter.arrAirport.code,
        depAirport:this.checkTicketFilter.depAirport.code
      }
      validateticket({ t: params }).then(res => {
        this.scheduleLoading = false;
        
        if (res.success) {
          this.tableData = res.data;
          if (this.tableData.length <= 0) {
            this.emptySlot = "未提取到机票信息,请核实后重新查询!";
          }
          else{
            this.detailVisible = true;
          }
        } else {
          //   this.emptySlot = res.errorMessage;
          // 弹框
          this.$alert(res.errorMessage, "", {
            confirmButtonText: "我知道了"
            // callback: action => {
            //   this.$message({
            //     type: "info",
            //     // message: `action: ${action}`
            //   });
            // }
          });

          this.changeValidateCode();
        }
      });
    },
    querySearch(panel,queryString,cb) {
      /* 新增选择机场组件 */
      this.$refs.arrAirportPannel.close();
      this.$refs.depAirportPannel.close();
      if(!queryString){
        this.$refs[panel].show();
        cb([]);
        return;
      }
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.chineseName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0 || restaurant.airportCode.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    handleSelectDepAirport(data){
        this.checkTicketFilter.depAirport.name = data.cityName;
        this.checkTicketFilter.depAirport.code = data.airportCode;
    },
    handleSelectArrAirport(data){
        this.checkTicketFilter.arrAirport.name = data.cityName;
        this.checkTicketFilter.arrAirport.code = data.airportCode;
    },
    exchangeAirport(){
       const tmp = {...this.checkTicketFilter.depAirport};
       this.checkTicketFilter.depAirport = {...this.checkTicketFilter.arrAirport};
       this.checkTicketFilter.arrAirport= {...tmp};
    }
  }
};
</script>
<style scoped lang="less">
.bg {
  background-color: rgb(226, 226, 226);
}

.labelcontent {
  width: 140px;
  text-align: left;
  padding-left: 5px;
}

.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.left_pannel {
  height: 500px;
  background-color: #f7f7f7;

  overflow: hidden;
}

.right_pannel {
  //   height: 800px;
  position: relative;
  background-color: #ffffff;
  //   border: 1px solid #ededed;
  border-radius: 10px;
  margin-top: 10px;
  padding-top: 50px;
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.warm-prompt {
  padding: 40px 50px 80px;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.service-query {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding: 8px 44px 0;
  width: 578px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  /* -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05); */
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-query .service-forms > li {
  margin-top: 20px;
}
.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}

.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}

.popcontext .tab {
  z-index: 1000;
  width: 240px;
}

.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

/* border-bottom: 1px solid #d6003a; */
.popcontext .tab li:nth-child(2) {
  border-top: 1px solid #d6003a;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}

.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}

.panel-header {
  border-bottom: 2px solid #e9e8e8;
  margin-top: 10px;
  width: 400px;
}

.panel-header .panel-tabs {
  display: block;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0;
}

.panel-tabs li {
  width: 50%;
  position: relative;
  float: left;
}

.panel-tabs li a {
  position: relative;
  display: block;
  margin: auto;
  width: 80px;
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  line-height: 42px;

  text-align: center;
  cursor: pointer;
}

.panel-tabs .clearfix:after {
  display: block;
  height: 0;
  content: "";
  clear: both;
  overflow: hidden;
}

.filter {
  width: 100%;
  float: left;
}

.filter .el-input {
  margin-top: 10px;
}

.el-date-editor .el-input {
  width: 100%;
}

.filter-title {
  margin-top: 15px;
  font-size: 12px;
  color: #7f7e7c;
  font-weight: 700;
}

.filter-input {
  position: relative;
  padding-top: 10px;
}

.filter-input .el-input input {
  height: 45px !important;
  line-height: 45px;
}

.filter-panel {
  background-color: #ffffff;
  position: absolute;
  top: 45px;
  left: 0;
  width: 424px;
  padding: 8px 16px 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.filter-panel-title {
  color: #9d9d9d;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-select-dropdown__item {
  padding-left: 15px;
}

.separet {
  height: 14px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin: 0 9px;
  margin-top: 50px;
}

.search-btn {
  width: 80px;
  height: 35px;
  margin-top: 20px;
  margin-left: 32px;
  background-color: #ff0505;
  color: #ffffff;
  border: 0;
  font-size: 14px;
  font-weight: 700;
}

.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #f7214b;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}

.panel-tabs li.active:after {
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ce212e;
}

.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 45px;
  line-height: 45px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.form-panel {
  .flex-center {
    justify-content: center;
    margin-top: 50px;
  }
  .form-label {
    min-width: 80px;
    text-align: right;
  }
  .el-input {
    width: 300px !important;
  }
  &-item {
    width: 400px;
    display: flex;
    margin: 0 auto;
    // justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}
.change-btn {
  text-align: left;
  font-size: 12px;
  margin-left: 10px;
  color: #009ed0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item{
  display: flex;
  width: 400px;
  margin: 0 auto;
  // justify-content: center;
  align-items: center;
  // text-align: center;
  line-height: 30px;
  &-title{
    color: #000000;
    min-width: 100px;
    text-align: right;
    margin-right: 20px;
  } 
  &-value{
    color: #515457;
  }
}
.route-wrap{
  display: inline-flex;
  align-items: center;
}
</style>
